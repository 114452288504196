import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {},
  mutations: {
    edit(state, payload) {
      state.isIOSApp = payload.isIOSApp
      state.isWx = payload.isWx
      state.isAndroidApp = payload.isAndroidApp
      state.isNoTitleBar = payload.isNoTitleBar
    },
    backToChoose(state, payload) {
      state.isBack = payload.isBack
    }
  },
  getters: {
    // 加工state成员给外界
  },
  actions: {
    // 异步操作
  },
  modules: {}
})

export default store
