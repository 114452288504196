<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
import {disabledScale} from './static/disabledScale.js'

export default {
  name: 'app',
  created() {
    const u = window.navigator.userAgent
    // ios终端
    const isIOSApp = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
    // android
    const isAndroidApp = (u.indexOf('Android') > -1)
    // android
    const isNoTitleBar = (u.indexOf('NoTitleBar') > -1)
    // 在微信环境内推
    const isWx = /MicroMessenger/gi.test(u)
    this.$store.commit(
      'edit',
      {
        isIOSApp: isIOSApp, isAndroidApp: isAndroidApp, isWx: isWx, isNoTitleBar: isNoTitleBar
      }
    )
  },
  mounted: function () {
    disabledScale()
  }
}
</script>

<style lang="less">
* {
  margin: 0;
  padding: 0;
}

html,
body {
  line-height: 1;
  // background-color: #f1f0f2;
  #app {
    position: relative;
    overflow-x: hidden;
  }

  /*自定义滚动条颜色*/

  ::-webkit-scrollbar {
    width: 0;
    height: 10px;
    background-color: transparent;
  }

  /*定义滚动条轨道 内阴影+圆角*/

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  /*定义滑块 内阴影+圆角*/

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: transparent;
  }

  /*自定义滚动条颜色结束*/

  ::-webkit-scrollbar-corner {
    background-color: transparent;
  }
}
</style>
