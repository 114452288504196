import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueClipboard from 'vue-clipboard2'
import clipboard from 'clipboard';
import rem from './static/rem'
import $ from 'jquery'
import axios from './utils/axios'
import  h5AppLink  from '@/utils/h5AppLink'
// import Vant from 'vant'
import Oss from './utils/upload'
Vue.use(Oss)
Vue.config.productionTip = false
Vue.prototype.$axios = axios;
Vue.prototype.$ = $;
Vue.prototype.clipboard = clipboard;
Vue.prototype.$h5AppLink = h5AppLink.h5AppLink;
Vue.prototype.ios = h5AppLink.isIOSApp;
Vue.use(VueClipboard)

// Vue.use(Vant)
router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})
new Vue({
  router,
  //store:store 和router一样，将我们创建的Vuex实例挂载到这个vue实例中
  store,
  render: h => h(App)
}).$mount('#app')
