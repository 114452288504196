/* create 2020/10/27 */
const u = window.navigator.userAgent
// iOS终端
const isIOSApp = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
// Android
const isAndroidApp = (u.indexOf('Android') > -1)

function h5AppLink(event, param) {
  let newEvent = event
  let newParam = param
  let largeVersion = false
  if (newEvent === 'version') {
    if (newParam.version) {
      const versionArr = newParam.version.split('.')
      if (Number(versionArr[0]) > 0) {
        largeVersion = true
      } else if (Number(versionArr[1]) > 1) {
        largeVersion = true
      } else if ((Number(versionArr[2]) > 5 && isAndroidApp) || (Number(versionArr[2]) >= 5 && isIOSApp)) {
        largeVersion = true
      }
    }
    if ((largeVersion && isAndroidApp) || (largeVersion && isIOSApp)) {
      newEvent = 'jumpTB'
      newParam = {
        url: newParam.url
      }
    } else {
      newEvent = 'openTbAuth'
      newParam = {
        authUrl: newParam.url,
        backUrl: newParam.backUrl
      }
    }
  }
  if (isIOSApp) {
    // 调用iOS原生方法
    window.webkit.messageHandlers.h5_app_link.postMessage(
      JSON.stringify({
        event: newEvent,
        param: newParam
      })
    )
  } else {
    // 调用Android原生方法
    window.android.h5_app_link(
      JSON.stringify({
        event: newEvent,
        param: newParam
      }))
  }
}

export default {
  h5AppLink,
  isIOSApp
}
