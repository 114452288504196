import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: '/',
  routes: [
    {
      path: '/',
      name: 'layout',
      meta: {
        title: '鲸选师 - 鲸挑细选，少花多赚'
      },
      component: () => import('../views/layout/index.vue'),
      children: [
        {
          path: '/index',
          name: 'index',
          meta: {
            title: '鲸选师 - 鲸挑细选，少花多赚'
          },
          component: () => import('../views/home/index.vue')
        },
        {
          path: '/home',
          name: 'home',
          meta: {
            title: '鲸选师 - 鲸挑细选，少花多赚'
          },
          component: () => import('../views/home/index.vue')
        },
        {
          path: '/download',
          name: 'download',
          meta: {
            title: '鲸选师 - 鲸挑细选，少花多赚'
          },
          component: () => import('../views/home/download.vue')
        },
        {
          path: '/logoutIntroduction',
          name: 'logoutIntroduction',
          meta: {
            title: '注销须知'
          },
          component: () => import('../views/logoutIntroduction/index.vue')
        },
        /*
        【H5外投活动页】话费充值外投页面
         */
        {
          path: '/jingxuanvip/one',
          name: 'phoneRechargeOne',
          meta: {
            title: '鲸选师省钱卡',
          },
          component: () => import('../views/phoneRecharging/one/versionOne.vue')
        },
        {
          path: '/jingxuanvip/pay',
          name: 'phoneOnePay',
          meta: {
            title: '订单支付',
          },
          component: () => import('../views/phoneRecharging/one/orderCheckOne.vue')
        },
        {
          path: '/jingxuanvip/two',
          name: 'phoneRechargeTwo',
          meta: {
            title: '鲸选师省钱卡',
          },
          component: () => import('../views/phoneRecharging/two/versionTwo.vue')
        },
        {
          path: '/jingxuanvip/check',
          name: 'orderCheck',
          meta: {
            title: '确认订单',
          },
          component: () => import('../views/phoneRecharging/two/orderCheckTwo.vue')
        },
        {
          path: '/phone/coupon',
          name: 'phoneCoupon',
          meta: {
            title: '话费券',
          },
          component: () => import('../views/phoneRecharging/phoneCoupon.vue')
        },
        {
          path: '/jingxuanvip/report',
          name: 'putReport',
          meta: {
            title: '投诉反馈',
          },
          component: () => import('../views/phoneRecharging/report.vue')
        },
        /*
        一元抢购活动
         */
        {
          path: '/jingxuan/privilege',
          name: 'jxPrivilege',
          meta: {
            title: '鲸选特权',
          },
          component: () => import('../views/jingxuanPrivilege/index.vue')
        },
        {
          path: '/purchase',
          name: 'purchase',
          meta: {
            title: '一元抢购',
          },
          component: () => import('../views/rushPurchase/index.vue')
        },
        {
          path: '/purchase/rules',
          name: 'purchaseRules',
          meta: {
            title: '一元抢规则',
          },
          component: () => import('../views/rushPurchase/rules.vue')
        },
        {
          path: '/pay/confirm',
          name: 'payConfirm',
          meta: {
            title: '支付页面',
          },
          component: () => import('../views/rushPurchase/payConfirm.vue')
        },
        {
          path: '/purchase/award',
          name: 'awardDetail',
          meta: {
            title: '开奖详情',
          },
          component: () => import('../views/rushPurchase/award.vue')
        },
        {
          path: '/purchase/invite',
          name: 'dilateInvite',
          meta: {
            title: '帮我助力膨胀红包'
          },
          component: () => import('../views/rushPurchase/dilateInvite.vue')
        },
        {
          path: '/cash/tb',
          name: 'tbCash',
          meta: {
            title: '专属补贴红包'
          },
          component: () => import('../views/cashGift/tbCash.vue')
        },
        {
          path: '/cash/jd',
          name: 'jdCash',
          meta: {
            title: '超级秒杀'
          },
          component: () => import('../views/cashGift/jdCash.vue')
        },
        {
          path: '/strategy',
          name: 'strategy',
          meta: {
            title: '双12省钱攻略'
          },
          component: () => import('../views/activityStrategy/index.vue')
        },
        {
          path: '/subsidies/all',
          name: 'all',
          meta: {
            title: '全网百亿补贴'
          },
          component: () => import('../views/activitySubsidies/all.vue')
        },
        {
          path: '/subsidies/pdd',
          name: 'pddSubsidies',
          meta: {
            title: '拼多多百亿补贴'
          },
          component: () => import('../views/activitySubsidies/pddSubsidies.vue')
        },
        {
          path: '/subsidies/jd',
          name: 'jdSubsidies',
          meta: {
            title: '京东百亿补贴'
          },
          component: () => import('../views/activitySubsidies/jdSubsidies.vue')
        },
        {
          path: '/subsidies/wph',
          name: 'wphSubsidies',
          meta: {
            title: '唯品会百亿补贴'
          },
          component: () => import('../views/activitySubsidies/wphSubsidies.vue')
        },
        {
          path: '/subsidies/sn',
          name: 'snSubsidies',
          meta: {
            title: '苏宁百亿补贴'
          },
          component: () => import('../views/activitySubsidies/snSubsidies.vue')
        },
        {
          path: '/subsidies',
          name: 'subsidies',
          meta: {
            title: '聚划算百亿补贴'
          },
          component: () => import('../views/activitySubsidies/index.vue')
        },
        {
          path: '/template',
          name: 'template',
          meta: {
            title: ' '
          },
          component: () => import('../views/activitySubsidies/activityTemplate.vue')
        },
        {
          path: '/daughter',
          name: 'daughter',
          meta: {
            title: '女儿们的私服穿搭'
          },
          component: () => import('../views/activityApp/daughter.vue')
        },
        {
          path: '/venue/sn',
          name: 'snVenue',
          meta: {
            title: '苏宁双12开门红'
          },
          component: () => import('../views/activityApp/snVenue.vue')
        },
        {
          path: '/venue/wph',
          name: 'wphVenue',
          meta: {
            title: '唯品会12.8店庆狂欢'
          },
          component: () => import('../views/activityApp/wphVenue.vue')
        },
        {
          path: '/packet/choose',
          name: 'choosePage',
          meta: {
            title: ''
          },
          component: () => import('../views/pullNew/choosePage.vue')
        },
        {
          path: '/packet/pull',
          name: 'pullNew',
          meta: {
            title: '年终现金福利'
          },
          component: () => import('../views/pullNew/index.vue')
        },
        {
          path: '/packet/wx',
          name: 'wxPacket',
          meta: {
            title: '年终现金红包'
          },
          component: () => import('../views/pullNew/wxPacket.vue')
        },
        {
          path: '/packet/help',
          name: 'helpPacket',
          meta: {
            title: '助力红包'
          },
          component: () => import('../views/pullNew/components/helpPacket.vue')
        },
        {
          path: '/test',
          name: 'test',
          meta: {
            title: 'app事件测试'
          },
          component: () => import('../views/test/index.vue')
        },
        {
          path: '/eggs',
          name: 'eggs',
          meta: {
            title: '天天开彩蛋'
          },
          component: () => import('../views/activitySubsidies/activityEggs.vue')
        },
        {
          path: '/coupon',
          name: 'coupon',
          meta: {
            title: '聚划算双11狂欢'
          },
          component: () => import('../views/activitySubsidies/coupon.vue')
        },
        {
          path: '/pdd',
          name: 'pdd',
          meta: {
            title: '拼多多双11会场'
          },
          component: () => import('../views/activitySubsidies/pddPacket.vue')
        },
        {
          path: '/packet',
          name: 'packet',
          meta: {
            title: '天猫双11超级红包'
          },
          component: () => import('../views/activityPacket/index.vue')
        },
        {
          path: '/surprise',
          name: 'surprise',
          meta: {
            title: '天猫双11惊喜红包'
          },
          component: () => import('../views/activityPacket/surprisePacket.vue')
        },
        {
          path: '/packet/jd',
          name: 'jdPacket',
          meta: {
            title: '京东双11惊喜红包'
          },
          component: () => import('../views/activityPacket/jdPacket.vue')
        },
        {
          path: '/activity',
          name: 'activity',
          meta: {
            title: '活动专区'
          },
          component: () => import('../views/activity/index.vue')
        },
        {
          path: '/advance',
          name: 'advance',
          meta: {
            title: '预售会场'
          },
          component: () => import('../views/advanceSale/index.vue')
        },
        {
          path: '/worker',
          name: 'workerBuy',
          meta: {
            title: '双12官方内购清单'
          },
          component: () => import('../views/activityApp/workerBuy.vue')
        },
        {
          path: '/teaching',
          name: 'teaching',
          meta: {
            title: '自购省钱返利秘籍'
          },
          component: () => import('../views/teaching/index.vue')
        },
        {
          path: '/about',
          name: 'about',
          meta: {
            title: '关于鲸选师'
          },
          component: () => import('../views/about/index.vue')
        },
        {
          path: '/question',
          name: 'question',
          meta: {
            title: '常见问题'
          },
          component: () => import('../views/question/index.vue')
        },
        {
          path: '/takeout',
          name: 'takeout',
          meta: {
            title: '外卖专区'
          },
          component: () => import('../views/takeOutCenter/index.vue'),
          children: [
            {
              path: '/takeout',
              name: 'takeoutCenter',
              meta: {
                title: '外卖返现'
              },
              component: () => import('../views/takeOutCenter/child/takeoutCenter.vue')
            },
            {
              path: '/takeout/details',
              name: 'takeoutDetails',
              meta: {
                title: '外卖返现'
              },
              component: () => import('../views/takeOutCenter/child/takeoutDetails.vue')
            }
          ]
        },
        {
          path: '/article/details',
          name: 'article',
          meta: {
            title: '文章详情'
          },
          component: () => import('../views/article/index.vue')
        },
        {
          path: '/colonel/privilege',
          name: 'colonelPrivilege',
          meta: {
            title: '升级条件'
          },
          component: () => import('../views/colonelPrivilege/index.vue')
        },
        {
          path: '/cashback/index',
          name: 'superCashback',
          meta: {
            title: '超级补贴'
          },
          component: () => import('../views/superCashback/index.vue')
        },
        {
          path: '/cashback/search',
          name: 'cashbackSearch',
          meta: {
            title: '超级补贴'
          },
          component: () => import('../views/cashbackSearch/index.vue')
        },
        {
          path: '/agreement',
          name: 'agreement',
          meta: {
            title: '用户协议'
          },
          component: () => import('../views/agreement/index.vue')
        },
        {
          path: '/privacy',
          name: 'privacy',
          meta: {
            title: '隐私政策'
          },
          component: () => import('../views/privacy/index.vue')
        },
        {
          path: '/table',
          name: 'table',
          meta: {
            title: '合作伙伴SDK或相关技术获取用户信息情况披露'
          },
          component: () => import('../views/table/index.vue')
        },
        {
          path: '/iframe',
          name: 'iframe',
          meta: {
            title: '外卖专区'
          },
          component: () => import('../views/iframe/index.vue')
        },
        {
          path: '/saleField',
          name: 'SaleField',
          meta: {
            title: '双十二主会场'
          },
          component: () => import('../views/saleField/index')
        },
        {
          path: '/rebate/description',
          name: 'superRebateDesc',
          meta: {
            title: '超返红包使用说明'
          },
          component: () => import('../views/superRebateDesc/index')
        },
        {
          path: '/shareGuide',
          name: 'shareGuide',
          meta: {
            title: '分享3步曲'
          },
          component: () => import('../views/shareGuide/index')
        }
      ]
    }
  ]
})

// 全局路由守卫
router.beforeEach((to, from, next) => {
  localStorage.setItem('token', to.query.token || '')
  localStorage.setItem('version', to.query.version || '')
  localStorage.setItem('channel', to.query.channel || '')
  localStorage.setItem('appClient', to.query.appClient || '')
  localStorage.setItem('clientType', to.query.clientType || '')
  next()
})

export default router
