/**
 * Created by longji on 2019/3/14.
 */
import axios from 'axios'
// import router from '../router'
import { Toast } from "vant"
import baseUrl from './baseUrl'
axios.defaults.withCredentials = true
axios.defaults.baseURL = baseUrl.baseUrl
// 创建axios实例
const service = axios.create({
  timeout: 8000, // 请求超时时间
})
//post请求的请求头
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

// request拦截器
service.interceptors.request.use(config => {
  if (localStorage.getItem('token')) {
    //token字段是要和后端协商好的
    config.headers.common["token"] = localStorage.getItem('token') || ''
    config.headers.common["version"] = localStorage.getItem('version') || ''
    config.headers.common["channel"] = localStorage.getItem('channel') || ''
    config.headers.common["appClient"] = localStorage.getItem('appClient') || ''
    config.headers.common["clientType"] = localStorage.getItem('clientType') || ''
  }
  return config
}, error => {
  Promise.reject(error)
});

// respone拦截器
service.interceptors.response.use(response => {
  // let Router = router;
  if(response.data.code === "SUCCESS"){
    return Promise.resolve(response)
  }
    return Promise.reject(response)
}, error => {
  Toast({
    position: "bottom",
    message: error.data.msg
  });
  return Promise.reject(error)
})

export default service
