/**
* Created by longji on 2020/9/3.
*/
let baseUrl = ''
let pageUrl = ''
const env = process.env.NODE_ENV === 'development' ? 'development' : process.env.VUE_APP_TITLE === 'test' ? 'test' : 'production'
switch (env) {
  case 'development':
    baseUrl = 'https://api.sandbox.jingxuanshi.net'
    pageUrl = 'https://m.sandbox.jingxuanshi.net'
    break
  case 'test':
    baseUrl = 'https://api.sandbox.jingxuanshi.net'
    pageUrl = 'https://m.sandbox.jingxuanshi.net'
    break
  case 'production':
    baseUrl = 'https://api.jingxuanshi.net'
    pageUrl = 'https://m.jingxuanshi.net'
    break
}
export default {baseUrl,pageUrl}
